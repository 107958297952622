import React from "react"

export default function PageHeader({ text }) {
  return (
    <>
      <h1 className="text-3xl lg:text-4xl font-bold text-center text-secondary uppercase ">
        {text}
      </h1>
    </>
  )
}
